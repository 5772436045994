<template>
  <div>
    <header class="header">
      <div class="header-text">付款给 {{ payOrderInfo.mchName || '玄智科技' }}</div>
      <div class="header-img">
        <img :src="avatar ? avatar : icon_member_default" alt=""/>
      </div>
    </header>
    <div class="plus-input">
      <!-- ￥字符 货币的符号-->
      <div class="S">
        <img src="../../assets/icon/S.svg" alt=""/>
      </div>

      <van-field v-model="money" type="number" :autofocus="true"/>

    </div>
    <ul class="plus-ul">
      <!-- 支付板块 -->
      <li style="border-radius:10px;">
        <!-- 支付金额板块 -->
        <div class="img-div">
          <img :src="wxImg" alt=""/>
          <div class="div-text">
            支付宝支付
          </div>
        </div>
      </li>
    </ul>

    <ul class="plus-ul" style="margin-top: 0px">
      <li style="border-radius:10px;">
        <div class="plus-remark">
          <van-field
              v-model="remark"
              type="textarea"
              maxlength="100"
              rows="3"
              autosize
              placeholder="请输入备注"
              show-word-limit
          />
        </div>
      </li>
    </ul>

    <div class="bnt-pay">
      <div
          class="bnt-pay-text"
          style="background-color:#1678ff"
          @click="pay"
      >
        付款
      </div>
    </div>
    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <van-loading  size="48" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {getNewPayPackage} from '@/api/api'
import config from "@/config";
import {Toast} from 'vant';

export default {

  data: function () {
    return {
      avatar: require("../../assets/images/zfb.jpeg"), // 商户头像默认
      resData: {},
      wxImg: require("../../assets/images/zfb.jpeg"), // 微信支付图片
      payOrderInfo: {}, //订单信息
      money: '', // 支付金额默认
      mchOrderNo: '',
      remark: '',
      show:false,
    }
  },

  mounted() {
  },

  methods: {
    pay() {
      if (!this.money) {
        Toast.fail('请输入付款金额');
        return
      }

      this.show = true

      let that = this;
      that.randomOrderNo()
      getNewPayPackage('alipay',this.money, this.mchOrderNo, this.remark).then(res => {

        //订单创建异常
        if (res.code != '0') {
          this.show = false
          return alert(res.msg || '订单创建异常');
        }

        if (res.data.orderState != 1) { //订单不是支付中，说明订单异常
          this.show = false
          return alert(res.data.errMsg || '订单异常');
        }

        location.href = res.data.codeUrl;
        this.show = false

      }).catch(res => {

        this.show = false
        // let data = JSON.parse(res.data)
        // location.href = data['alipay_trade_precreate_response']['qr_code']
        that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
      });
    },


    randomOrderNo() {
      this.mchOrderNo = 'M' + new Date().getTime() + Math.floor(Math.random() * (9999 - 1000) + 1000)
    },

  }


}
</script>
<style lang="css" scoped>
@import './pay.css';
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>